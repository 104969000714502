import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './hero.css';
import Item from 'components/hero/item';

const Hero = ({ item }) => (
  <Container>
    <Item {...item} key="0" />
  </Container>
);

Hero.propTypes = {
  item: PropTypes.object.isRequired,
};

export default Hero;
