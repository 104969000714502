import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import Box from 'components/box';
import Grid from 'components/grid';
import Hero from 'components/hero';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import { graphql } from 'gatsby';

const Index = ({ data }) => (
  <Layout>
    <Link to="/contact">
      <Hero item={data.homeJson.hero} />
    </Link>
    <Box>
      <div
        dangerouslySetInnerHTML={{
          __html: data.homeJson.content.childMarkdownRemark.html,
        }}
      />
    </Box>
    <Grid classes="four-grid grey-background pt-15 pb-15">
      {data.homeJson.gallery.map((value, index) => {
        return (
          <Img
            key={index}
            fluid={value.image ? value.image.childImageSharp.fluid : {}}
            alt={value.title}
          />
        );
      })}
    </Grid>
    <Box>
      <div
        dangerouslySetInnerHTML={{
          __html: data.homeJson.outro.childMarkdownRemark.html,
        }}
      />
    </Box>
    <Grid classes="four-grid grey-background pt-15 pb-15">
      {data.homeJson.gallery2.map((value, index) => {
        return (
          <Img
            key={index}
            fluid={value.image ? value.image.childImageSharp.fluid : {}}
            alt={value.title}
          />
        );
      })}
    </Grid>
    <Box>
      <div
        dangerouslySetInnerHTML={{
          __html: data.homeJson.outro3.childMarkdownRemark.html,
        }}
      />
    </Box>
    <Grid classes="four-grid grey-background pt-15 pb-15">
      {data.homeJson.gallery3.map((value, index) => {
        return (
          <Img
            key={index}
            fluid={value.image ? value.image.childImageSharp.fluid : {}}
            alt={value.title}
          />
        );
      })}
    </Grid>
  </Layout>
);

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    homeJson {
      title
      content {
        childMarkdownRemark {
          html
          headings {
            depth
            value
          }
          frontmatter {
            # Assumes you're using title in your frontmatter.
            title
          }
        }
      }
      outro {
        childMarkdownRemark {
          html
          headings {
            depth
            value
          }
          frontmatter {
            # Assumes you're using title in your frontmatter.
            title
          }
        }
      }
      outro3 {
        childMarkdownRemark {
          html
          headings {
            depth
            value
          }
          frontmatter {
            # Assumes you're using title in your frontmatter.
            title
          }
        }
      }
      hero {
        title
        copy
        image {
          childImageSharp {
            fluid(maxHeight: 350, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      gallery {
        title
        copy
        image {
          childImageSharp {
            fluid(maxHeight: 350, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      gallery2 {
        title
        copy
        image {
          childImageSharp {
            fluid(maxHeight: 350, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      gallery3 {
        title
        copy
        image {
          childImageSharp {
            fluid(maxHeight: 350, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`;
