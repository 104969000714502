import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './grid.css';

const Grid = ({ children, classes }) => (
  <Container className={classes}>{children}</Container>
);

Grid.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.string,
};

export default Grid;
