import MEDIA from 'helpers/mediaTemplates';
import styled from 'styled-components';

export const Container = styled.div`
  &.four-grid {
    display: grid;
    grid-template-columns: 24% 24% 24% 25%;
    grid-gap: 1%;
    ${MEDIA.TABLET`
      display: block;
    `};
  }
`;
